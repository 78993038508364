import React from "react";
import theme from "theme";
import { Theme, Text, Strong, Section, Image, Box, Em } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"services"} />
		<Helmet>
			<title>
				ВелоГеній
			</title>
			<meta name={"description"} content={"Ласкаво просимо до ВелоГеній — майстерні для справжніх фанатів велосипедів!\n"} />
			<meta property={"og:title"} content={"ВелоГеній"} />
			<meta property={"og:description"} content={"Ласкаво просимо до ВелоГеній — майстерні для справжніх фанатів велосипедів!\n"} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/66ed22b6e5d277001897f3b4/images/6555144.png?v=2024-09-20T10:02:55.402Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/66ed22b6e5d277001897f3b4/images/6555144.png?v=2024-09-20T10:02:55.402Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/66ed22b6e5d277001897f3b4/images/6555144.png?v=2024-09-20T10:02:55.402Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/66ed22b6e5d277001897f3b4/images/6555144.png?v=2024-09-20T10:02:55.402Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/66ed22b6e5d277001897f3b4/images/6555144.png?v=2024-09-20T10:02:55.402Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/66ed22b6e5d277001897f3b4/images/6555144.png?v=2024-09-20T10:02:55.402Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/66ed22b6e5d277001897f3b4/images/6555144.png?v=2024-09-20T10:02:55.402Z"} />
		</Helmet>
		<Components.Header>
			<Override slot="text6" />
			<Override slot="text" />
			<Override slot="text2" />
			<Override slot="text3" />
			<Override slot="link1" />
			<Override slot="text5" />
			<Override slot="text1" />
			<Override slot="link" />
		</Components.Header>
		<Components.Header2 />
		<Section padding="80px 0" sm-padding="40px 0">
			<Override slot="SectionContent" align-items="center" />
			<Text
				as="h2"
				font="--headline1"
				md-font="--headline2"
				margin="20px 0 0 0"
				text-align="center"
			>
				Наші послуги
			</Text>
			<Text as="p" font="--lead" margin="20px 0 20px 0" text-align="center">
				У ВелоГеній ми надаємо широкий спектр послуг, щоб кожен велосипедист міг насолоджуватися безпечною та комфортною поїздкою. Наші майстри мають глибокі знання та використовують передове обладнання для вирішення будь-яких технічних проблем. Від регулярного технічного обслуговування до комплексного ремонту — ми пропонуємо якісні послуги, щоб ви завжди були впевнені у своєму велосипеді.
				<br />
				<br />
				<br />
				<Strong>
					У ВелоГеній ми надаємо різноманітні послуги, які відповідають потребам кожного велосипедиста. Нижче наведено деякі з наших основних послуг:
				</Strong>
			</Text>
		</Section>
		<Section padding="0 0 100px 0" quarkly-title="List-2">
			<Box
				min-width="100px"
				min-height="100px"
				display="grid"
				grid-template-columns="repeat(2, 1fr)"
				grid-gap="34px"
				md-grid-template-columns="1fr"
				md-grid-gap="36px 0"
			>
				<Box min-width="100px" min-height="100px">
					<Image
						src="https://uploads.quarkly.io/66ed22b6e5d277001897f3b4/images/workshops.jpg?v=2024-09-20T10:02:55.415Z"
						display="block"
						margin="0px 0px 20px 0px"
						max-width="100%"
						max-height="300px"
						width="100%"
						height="100%"
						object-fit="cover"
						srcSet="https://smartuploads.quarkly.io/66ed22b6e5d277001897f3b4/images/workshops.jpg?v=2024-09-20T10%3A02%3A55.415Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/66ed22b6e5d277001897f3b4/images/workshops.jpg?v=2024-09-20T10%3A02%3A55.415Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/66ed22b6e5d277001897f3b4/images/workshops.jpg?v=2024-09-20T10%3A02%3A55.415Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/66ed22b6e5d277001897f3b4/images/workshops.jpg?v=2024-09-20T10%3A02%3A55.415Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/66ed22b6e5d277001897f3b4/images/workshops.jpg?v=2024-09-20T10%3A02%3A55.415Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/66ed22b6e5d277001897f3b4/images/workshops.jpg?v=2024-09-20T10%3A02%3A55.415Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/66ed22b6e5d277001897f3b4/images/workshops.jpg?v=2024-09-20T10%3A02%3A55.415Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
					<Text margin="0px 0px 0px 0px" font="normal 300 20px/1.5 --fontFamily-sansHelvetica" color="--dark">
						Базове налаштування: Технічне обслуговування основних вузлів вашого велосипеда для забезпечення його належної роботи.
						<br />
						{" "}Регулювання гальм: Вдосконалення гальм для безпеки та ефективного гальмування.
						<br />
						{" "}Налаштування передач: Плавне перемикання передач для зручної їзди.
						<br />
						{" "}Накачування шин: Оптимальний тиск у шинах для кращого зчеплення та комфорту.
						<br />
						{" "}Огляд на безпеку: Комплексна перевірка всіх компонентів для виявлення потенційних проблем.
					</Text>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Image
						src="https://uploads.quarkly.io/66ed22b6e5d277001897f3b4/images/Hoffy-Cycles.jpg?v=2024-09-20T10:02:55.410Z"
						display="block"
						margin="0px 0px 20px 0px"
						max-width="100%"
						max-height="300px"
						width="100%"
						height="100%"
						object-fit="cover"
						object-position="top"
						srcSet="https://smartuploads.quarkly.io/66ed22b6e5d277001897f3b4/images/Hoffy-Cycles.jpg?v=2024-09-20T10%3A02%3A55.410Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/66ed22b6e5d277001897f3b4/images/Hoffy-Cycles.jpg?v=2024-09-20T10%3A02%3A55.410Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/66ed22b6e5d277001897f3b4/images/Hoffy-Cycles.jpg?v=2024-09-20T10%3A02%3A55.410Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/66ed22b6e5d277001897f3b4/images/Hoffy-Cycles.jpg?v=2024-09-20T10%3A02%3A55.410Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/66ed22b6e5d277001897f3b4/images/Hoffy-Cycles.jpg?v=2024-09-20T10%3A02%3A55.410Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/66ed22b6e5d277001897f3b4/images/Hoffy-Cycles.jpg?v=2024-09-20T10%3A02%3A55.410Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/66ed22b6e5d277001897f3b4/images/Hoffy-Cycles.jpg?v=2024-09-20T10%3A02%3A55.410Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
					<Text margin="0px 0px 0px 0px" font="normal 300 20px/1.5 --fontFamily-sansHelvetica" color="--dark">
						Повний ремонт: Глибоке очищення, діагностика та ремонт, щоб ваш велосипед виглядав і працював як новий.
						<br />
						{" "}Чистка компонентів: Видалення бруду та пилу з усіх деталей для покращення їхньої роботи.
						<br />
						{" "}Змащення: Нанесення мастила на всі рухомі частини для безперебійної їзди.
						<br />
						{" "}Огляд вузлів: Детальна перевірка всіх компонентів для визначення рівня зношеності.
						<br />
						{" "}Збирання: Точне складання після проведених робіт для оптимальної роботи.
					</Text>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Image
						src="https://uploads.quarkly.io/66ed22b6e5d277001897f3b4/images/tune-up-main-bg.jpg?v=2024-09-20T10:02:55.414Z"
						display="block"
						margin="0px 0px 20px 0px"
						max-width="100%"
						max-height="300px"
						object-fit="cover"
						width="100%"
						srcSet="https://smartuploads.quarkly.io/66ed22b6e5d277001897f3b4/images/tune-up-main-bg.jpg?v=2024-09-20T10%3A02%3A55.414Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/66ed22b6e5d277001897f3b4/images/tune-up-main-bg.jpg?v=2024-09-20T10%3A02%3A55.414Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/66ed22b6e5d277001897f3b4/images/tune-up-main-bg.jpg?v=2024-09-20T10%3A02%3A55.414Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/66ed22b6e5d277001897f3b4/images/tune-up-main-bg.jpg?v=2024-09-20T10%3A02%3A55.414Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/66ed22b6e5d277001897f3b4/images/tune-up-main-bg.jpg?v=2024-09-20T10%3A02%3A55.414Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/66ed22b6e5d277001897f3b4/images/tune-up-main-bg.jpg?v=2024-09-20T10%3A02%3A55.414Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/66ed22b6e5d277001897f3b4/images/tune-up-main-bg.jpg?v=2024-09-20T10%3A02%3A55.414Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
					<Text margin="0px 0px 0px 0px" font="normal 300 20px/1.5 --fontFamily-sansHelvetica" color="--dark">
						Обслуговування коліс: Регулювання, перевірка та ремонт коліс для плавного ходу та безпеки.
						<br />
						{" "}Вирівнювання колеса: Усуваємо хитання та забезпечуємо прямий рух.
						<br />
						{" "}Ремонт втулок: Очищення та змащення втулок для безперебійного обертання коліс.
						<br />
						{" "}Заміна спиць: Встановлюємо нові спиці для відновлення цілісності колеса.
						<br />
						{" "}Заміна шин і камер: Оновлюємо шини та камери для безперервної поїздки.
					</Text>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Image
						src="https://uploads.quarkly.io/66ed22b6e5d277001897f3b4/images/GettyImages-615281154-4d3a25e.webp?v=2024-09-20T10:02:55.408Z"
						display="block"
						margin="0px 0px 20px 0px"
						max-width="100%"
						max-height="300px"
						object-fit="cover"
						width="100%"
						srcSet="https://smartuploads.quarkly.io/66ed22b6e5d277001897f3b4/images/GettyImages-615281154-4d3a25e.webp?v=2024-09-20T10%3A02%3A55.408Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/66ed22b6e5d277001897f3b4/images/GettyImages-615281154-4d3a25e.webp?v=2024-09-20T10%3A02%3A55.408Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/66ed22b6e5d277001897f3b4/images/GettyImages-615281154-4d3a25e.webp?v=2024-09-20T10%3A02%3A55.408Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/66ed22b6e5d277001897f3b4/images/GettyImages-615281154-4d3a25e.webp?v=2024-09-20T10%3A02%3A55.408Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/66ed22b6e5d277001897f3b4/images/GettyImages-615281154-4d3a25e.webp?v=2024-09-20T10%3A02%3A55.408Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/66ed22b6e5d277001897f3b4/images/GettyImages-615281154-4d3a25e.webp?v=2024-09-20T10%3A02%3A55.408Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/66ed22b6e5d277001897f3b4/images/GettyImages-615281154-4d3a25e.webp?v=2024-09-20T10%3A02%3A55.408Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
					<Text margin="0px 0px 0px 0px" font="normal 300 20px/1.5 --fontFamily-sansHelvetica" color="--dark">
						Гальмівна система: Забезпечуємо справність гальмів для вашої безпеки.
						<br />
						{" "}Заміна гальмівних колодок: Встановлюємо нові колодки для надійного гальмування.
						<br />
						{" "}Прокачування гідравлічних гальм: Відновлюємо ефективність гальмування через видалення повітря.
						<br />
						{" "}Заміна тросів: Налаштування та заміна гальмівних тросів для кращої реакції.
						<br />
						{" "}Регулювання супортів: Точне налаштування для бездоганного контакту гальмів.
					</Text>
				</Box>
			</Box>
		</Section>
		<Section padding="0 0 100px 0" quarkly-title="List-2">
			<Box
				min-width="100px"
				min-height="100px"
				display="grid"
				grid-template-columns="repeat(2, 1fr)"
				grid-gap="0 34px"
				md-grid-template-columns="1fr"
				md-grid-gap="36px 0"
			>
				<Box min-width="100px" min-height="100px">
					<Image
						src="https://uploads.quarkly.io/66ed22b6e5d277001897f3b4/images/Blog-Bicycle-Bike-Tune-up_1024x500_crop_center_426c6597-4b2e-4de0-a7c5-416501395cef.webp?v=2024-09-20T10:02:55.402Z"
						display="block"
						margin="0px 0px 20px 0px"
						max-width="100%"
						max-height="300px"
						width="100%"
						height="100%"
						object-fit="cover"
						srcSet="https://smartuploads.quarkly.io/66ed22b6e5d277001897f3b4/images/Blog-Bicycle-Bike-Tune-up_1024x500_crop_center_426c6597-4b2e-4de0-a7c5-416501395cef.webp?v=2024-09-20T10%3A02%3A55.402Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/66ed22b6e5d277001897f3b4/images/Blog-Bicycle-Bike-Tune-up_1024x500_crop_center_426c6597-4b2e-4de0-a7c5-416501395cef.webp?v=2024-09-20T10%3A02%3A55.402Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/66ed22b6e5d277001897f3b4/images/Blog-Bicycle-Bike-Tune-up_1024x500_crop_center_426c6597-4b2e-4de0-a7c5-416501395cef.webp?v=2024-09-20T10%3A02%3A55.402Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/66ed22b6e5d277001897f3b4/images/Blog-Bicycle-Bike-Tune-up_1024x500_crop_center_426c6597-4b2e-4de0-a7c5-416501395cef.webp?v=2024-09-20T10%3A02%3A55.402Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/66ed22b6e5d277001897f3b4/images/Blog-Bicycle-Bike-Tune-up_1024x500_crop_center_426c6597-4b2e-4de0-a7c5-416501395cef.webp?v=2024-09-20T10%3A02%3A55.402Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/66ed22b6e5d277001897f3b4/images/Blog-Bicycle-Bike-Tune-up_1024x500_crop_center_426c6597-4b2e-4de0-a7c5-416501395cef.webp?v=2024-09-20T10%3A02%3A55.402Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/66ed22b6e5d277001897f3b4/images/Blog-Bicycle-Bike-Tune-up_1024x500_crop_center_426c6597-4b2e-4de0-a7c5-416501395cef.webp?v=2024-09-20T10%3A02%3A55.402Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
					<Text margin="0px 0px 0px 0px" font="normal 300 20px/1.5 --fontFamily-sansHelvetica" color="--dark">
						Трансмісія: Очищення та регулювання всіх компонентів трансмісії для плавної їзди.
						<br />
						{" "}Очищення ланцюга: Видалення накопиченого бруду для продовження терміну служби ланцюга.
						<br />
						{" "}Заміна касети: Встановлюємо нові касети для плавного перемикання передач.
						<br />
						{" "}Налаштування перемикачів: Оптимізація для чіткого перемикання передач.
						<br />
						{" "}Обслуговування шатунів: Очищення та змащення для передачі потужності на педалі.
					</Text>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Text margin="0px 0px 20px 0px" font="--headline3">
						<Em>
							Хочете дізнатися більше про наші послуги? Звертайтеся до нас або завітайте до нашої майстерні. Ми завжди готові допомогти вам та вашому велосипеду, забезпечуючи високий рівень обслуговування. Приходьте до ВелоГеній — і переконайтеся у нашому професіоналізмі!
						</Em>
					</Text>
				</Box>
			</Box>
		</Section>
		<Section padding="80px 0" sm-padding="40px 0">
			<Override slot="SectionContent" align-items="center" />
			<Text
				as="h2"
				font="--headline1"
				md-font="--headline2"
				margin="20px 0 0 0"
				text-align="center"
			>
				Їдьте з упевненістю
			</Text>
			<Text as="p" font="--lead" margin="20px 0 20px 0" text-align="center">
				У ВелоГеній ми дбаємо про те, щоб ваш велосипед завжди був у найкращому стані. Довірте нам свій байк — і ми забезпечимо його професійний догляд.
				<br />
				<br />
				З нетерпінням чекаємо на вас у нашій майстерні, щоб допомогти вам підтримувати безпечну та комфортну поїздку!
			</Text>
		</Section>
		<Components.Footernew />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65b7ae60009abe001f027d1c"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});